import firebase from "firebase";

import "firebase/auth/dist/index.cjs";
import "firebase/database/dist/index.cjs";

const defaultConfig = {
  apiKey: "AIzaSyB-94sJ1Er2vduXmfJE-r5zapZK8XBpV2A",
  authDomain: "tapacenterdev.firebaseapp.com",
  databaseURL: "https://tapacenterdev.firebaseio.com",
  projectId: "tapacenterdev",
  storageBucket: "tapacenterdev.appspot.com",
  messagingSenderId: "901456278283",
  appId: "1:901456278283:web:147013eef1968eade1e80e"
};

if (!firebase.apps.length) {
  firebase.initializeApp(defaultConfig);
}

//Use Admin SDK
const auth = firebase.auth();
const db = firebase.database();
const functions = firebase.functions();
const store = firebase.firestore();
const storage = firebase.storage();

export { auth, db, functions, storage, store };
