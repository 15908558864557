import React, { useRef, useEffect } from "react";
import { functions } from "../../firebase/firebase";

// MUI
import { ListItem, ListItemText, makeStyles } from "@material-ui/core";

// MUI Colors
import { lightBlue } from "@material-ui/core/colors";

// MUI Icons
import { KeyboardArrowRight } from "@material-ui/icons";

// Animations
import styled, { css, keyframes } from "styled-components";
import DeviceSlider from "./Slider";
import DeviceToggle from "./Toggle/DeviceToggle";

// Util
import clsx from "clsx";

// Styles
const updateFlash = keyframes`
  0% {
    background: white;
  }
  50% {
    background: ${lightBlue[100]};
  }
  100% {
    background: white;
  }
`;

const animation = css`
  animation: ${updateFlash} 1s ease-out;
`;

const AnimatedComponent = styled(ListItem)`
  ${props => (props.flash === "true" ? animation : false)}
`;

const useStyles = makeStyles(() => ({
  secondary: {
    display: "flex",
    alignItems: "center",
    height: 24
  },
  slider: {
    flexWrap: "wrap",
    marginBottom: "2rem"
  }
}));

export default function Device({ point, socketData, name, profile }) {
  // New Value Handlers
  const [newValue, setNewValue] = React.useState();
  const postData = functions.httpsCallable("postData");

  console.log(point);

  // Classes
  const classes = useStyles();

  // Store data in ref
  const streamingData = useRef(point.data.displayString);

  // Display flash
  const [flash, setFlash] = React.useState(false);

  // eslint-disable-next-line
  useEffect(() => {
    // Set incoming data to the ref if there's a change
    if (point.id === socketData.id) {
      setFlash(true);
      if (streamingData.current !== socketData.data.value) {
        streamingData.current = socketData.data.displayString;
      }
    } else {
      // Wait a second to let the animation finish, then remove class
      const timer = setTimeout(() => {
        setFlash(false);
      }, 1000);

      // Clean up timer on unmount
      return () => clearTimeout(timer);
    }
  });

  const handleCommitChange = (e, value) => {
    postData({ api: profile.api, route: "points", id: point.id, value })
      .then(res => console.log(res))
      .catch(e => console.log(e));
  };

  return (
    <AnimatedComponent
      flash={flash.toString()}
      className={clsx({
        [classes.slider]: point.dataType === "number" && !point.readOnly
      })}
    >
      <ListItemText
        primary={name}
        secondary={
          point.data.displayString === streamingData.current ? (
            point.data.displayString
          ) : (
            <>
              {point.data.displayString} <KeyboardArrowRight />{" "}
              <span style={{ fontWeight: "bolder" }}>
                {streamingData.current}
              </span>
            </>
          )
        }
        classes={{ secondary: classes.secondary }}
      />
      {!point.readOnly && point.dataType === "number" ? (
        <DeviceSlider
          min={point.min}
          max={point.max > 100 ? 100 : point.max}
          type={point.dataObjectType}
          value={point.data.value}
          setNewValue={setNewValue}
          handleCommitChange={handleCommitChange}
        />
      ) : !point.readOnly && point.dataType === "boolean" ? (
        <DeviceToggle
          value={point.data.value}
          setNewValue={setNewValue}
          handleCommitChange={handleCommitChange}
        />
      ) : (
        <span />
      )}
    </AnimatedComponent>
  );
}
