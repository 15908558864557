import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { functions } from "../firebase/firebase";
import { isLoaded } from "react-redux-firebase";

// MUI
import {
  FormControl,
  Select,
  InputBase,
  MenuItem,
  InputLabel,
  Grid,
  Typography,
  Divider,
  Collapse,
  IconButton
} from "@material-ui/core";

// MUI Hooks
import { makeStyles, withStyles } from "@material-ui/core/styles";

// MUI Icons
import {
  Search,
  Whatshot,
  WbIncandescentOutlined,
  Event,
  Warning,
  Poll,
  BatteryChargingFull,
  ChromeReaderMode,
  Memory,
  AddAlarm,
  Security
} from "@material-ui/icons";

// MUI Components
import DashboardMenu from "../components/Dashboard/DashboadMenu";
import { push } from "connected-react-router";
import clsx from "clsx";
import { UPDATE_SELECTION_ASPECT } from "../actions";
import DeviceDialog from "../components/NewDashboard/DeviceDialog";

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  }
}))(InputBase);

const dashboardIcons = [
  {
    name: "Climate",
    icon: <Whatshot />,
    value: "/climate",
    active: true
  },
  {
    name: "Lights",
    icon: <WbIncandescentOutlined />,
    value: "/lights",
    active: true
  },
  {
    name: "Calendar",
    icon: <Event />,
    value: "/calendars",
    active: true
  },
  {
    name: "Alarms",
    icon: <Warning />,
    value: "/alarms",
    active: true
  },
  {
    name: "History",
    icon: <ChromeReaderMode />,
    value: "/history",
    active: true
  },
  {
    name: "Analytics",
    icon: <Poll />,
    value: "/analytics"
  },
  {
    name: "Energy",
    icon: <BatteryChargingFull />,
    value: "/energy"
  },

  {
    name: "ML",
    icon: <Memory />,
    value: "/ml"
  },
  {
    name: "Schedule",
    icon: <AddAlarm />,
    value: "/schedule"
  },
  {
    name: "Security",
    icon: <Security />,
    value: "/security"
  }
];

const useStyles = makeStyles(theme => ({
  active: {
    border: `1px solid ${theme.palette.primary.main}`
  },
  inactive: {
    border: "1px solid"
  },
  navigation: {
    border: `1px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main
  },
  expanded: {
    background: theme.palette.secondary.main,
    color: "white"
  }
}));

const SiteSelector = ({ clientData, values, setValues }) => {
  const { site } = values;
  const { setSite } = setValues;

  const dClient = clientData.filter(
    client => client.client.id === values.client
  );

  return (
    <FormControl>
      <InputLabel id="demo-customized-select-label">Select Site</InputLabel>
      <Select
        id="demo-customized-select"
        value={site}
        onChange={e => setSite(e.target.value)}
        input={<BootstrapInput />}
      >
        {dClient[0].client.sites.map(dSite => {
          return (
            <MenuItem key={dSite.id} value={dSite.id}>
              {dSite.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

const NewDashboard = ({ profile, data, reroute, selectClients }) => {
  const classes = useStyles();

  // Dialog
  const [openDeviceDialog, setOpenDeviceDialog] = React.useState(false);

  // Status
  const [fetching, setFetching] = React.useState(false);
  const [nav] = React.useState(true);

  // Client data from API
  const [clientData, setClientData] = React.useState(data.clients);

  //   State
  const [client, setClient] = React.useState("");
  const [site, setSite] = React.useState("");

  //   Functions
  const fetchClients = functions.httpsCallable("fetchEndpoint");
  const fetchBuildings = functions.httpsCallable("fetchEndpoint");

  React.useEffect(() => {
    //   Once profile is loaded, fetch if there aren't clients
    if (isLoaded(profile) && !data.clients.length && !fetching) {
      setFetching(true);
      const data = fetchClients({ route: "clients", api: profile.api });
      data
        .then(res => {
          if (res) {
            setFetching(false);
            setClientData(JSON.parse(res.data));
          }
        })
        .catch(e => {
          setFetching(false);
          console.log(e);
          return e;
        });
    }
    // eslint-disable-next-line
  }, [data.clients, profile]);

  //   Check for auto-selecting client and sites
  React.useEffect(() => {
    if (!client && clientData.length === 1) {
      setClient(clientData[0].client.id);
    }

    if (client) {
      const data = clientData.filter(dClient => dClient.client.id === client);

      if (data.length && data[0].client.sites.length === 1) {
        setSite(data[0].client.sites[0].id);
      }
    }
    // eslint-disable-next-line
  }, [client, site, clientData]);

  //   Render
  return (
    <DashboardMenu
      profile={profile}
      clientData={clientData}
      fetching={fetching}
      reroute={reroute}
      selectClients={selectClients}
      setValues={{ setClient, setSite }}
      values={{ client, site }}
    >
      {/* Device Dialog */}

      <DeviceDialog
        profile={profile}
        open={openDeviceDialog}
        close={() => setOpenDeviceDialog(false)}
        api={profile.api}
        client={client}
        site={site}
        fetchBuildings={fetchBuildings}
      />

      {/* Device Dialog */}
      <Grid container>
        <Grid item xs={12}>
          {/* If no client selected, prompt select */}
          {!client && (
            <Typography variant="h4" align="center">
              {fetching || !clientData.length
                ? "Fetching your clients..."
                : "Clients loaded. Please select a client from the menu."}
            </Typography>
          )}

          {/* Client Data */}
          {clientData
            .filter(dClient => {
              return dClient.client.id === client;
            })
            .map(dClient => {
              return (
                <Grid
                  container
                  key={dClient.client.id}
                  style={{ padding: "0 1rem" }}
                >
                  <Grid item xs={12} sm={6}>
                    <Typography variant="caption" style={{ fontWeight: 800 }}>
                      Client
                    </Typography>
                    <Typography
                      variant="h4"
                      style={{ padding: "0 2rem 1rem 0" }}
                    >
                      {dClient.client.name}
                    </Typography>
                  </Grid>

                  {/* Auto Select Site */}
                  {dClient.client.sites.length === 1 ? (
                    <Grid item xs={12} sm={6} style={{ paddingBottom: "1rem" }}>
                      <Typography variant="caption" style={{ fontWeight: 800 }}>
                        Site
                      </Typography>
                      <Typography variant="h4">
                        {dClient.client.sites[0].name}
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={6} style={{ paddingBottom: "1rem" }}>
                      <SiteSelector
                        clientData={clientData}
                        values={(client, site)}
                        setValues={(setClient, setSite)}
                      />
                    </Grid>
                  )}

                  <Divider style={{ margin: 0 }} />
                </Grid>
              );
            })}
        </Grid>

        {/* With client and site selected, proceed with full layout */}
        <Grid container>
          <Collapse
            in={Boolean(client) && Boolean(site)}
            timeout="auto"
            unmountOnExit
            style={{ width: "100%" }}
          >
            <Grid container style={{ justifyContent: "space-evenly" }}>
              {/* Search */}
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  margin: "1rem"
                }}
              >
                <IconButton
                  className={clsx(classes.navigation, {
                    [classes.expanded]: !nav
                  })}
                  onClick={() => setOpenDeviceDialog(true)}
                >
                  <Search />
                </IconButton>
                <Typography
                  variant="subtitle1"
                  style={{
                    fontVariant: "all-small-caps",
                    fontSize: "1.25rem"
                  }}
                >
                  Find A Device
                </Typography>
              </Grid>

              <Collapse
                in={nav}
                timeout="auto"
                unmountOnExit
                style={{ width: "100%" }}
              >
                <Grid container>
                  {dashboardIcons.map(icon => {
                    return (
                      <Grid
                        key={icon.name}
                        item
                        xs={4}
                        sm={3}
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "center",
                          padding: "1rem"
                        }}
                      >
                        <IconButton
                          disabled={!icon.active}
                          onClick={() => reroute(`${icon.value}/${site}`)}
                          color={icon.active ? "primary" : "inherit"}
                          className={
                            icon.active ? classes.active : classes.inactive
                          }
                        >
                          {icon.icon}
                        </IconButton>
                        <Typography
                          variant="subtitle1"
                          style={{
                            width: "100%",
                            textAlign: "center",
                            fontVariant: "all-small-caps",
                            fontSize: "1.25rem"
                          }}
                        >
                          {icon.name}
                        </Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              </Collapse>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </DashboardMenu>
  );
};

NewDashboard.propTypes = {
  data: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired
};

export default connect(null, dispatch => ({
  reroute: route => dispatch(push(route)),
  selectClients: clients =>
    dispatch({
      type: UPDATE_SELECTION_ASPECT,
      aspect: "clients",
      payload: clients
    })
}))(NewDashboard);
